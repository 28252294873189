div.emailForm{
    min-height: 400px;
    max-width: 600px;
    text-align: center;

    p.header {
        color: #222;
        font-size: 3rem;
        font-family: 'Bebas Neue', cursive;
    }

    .paragraph{
        text-align: left;
    }

    .submitBtn {
        background: #2b2d2f;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font: 400 1.5rem 'Bebas Neue', cursive;
        height: 80px;
        left: 0;
        margin: 0 auto;
        right: 0;
        text-align: center;
        width: 200px;
    }
}

div.confirmModal{
    font: 700 1.4rem "Cerebri Sans Pro", sans-serif;
    div.modalHeader{
        border-bottom: none;
    }
    p{
        font: 700 1.1rem/1.8rem "Cerebri Sans Pro", sans-serif; 
    }
    button.modalClose{
        font: 700 1rem "Cerebri Sans Pro", sans-serif;
        padding:0.5rem;
    }
}