/*fonts.css*/
@font-face {
    font-family: 'Cerebri Sans Pro SemiBold'; /*font 이름*/
    font-style: 'normal'; /* font 스타일 정의 (기울임, 일반) */
    font-weight: 400; /* font 굵기 정의 */
    /* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
    src: url('../public/fonts/CerebriSansPro-SemiBold.ttf');
}

@font-face {
    font-family: 'Cerebri Sans Pro'; /*font 이름*/
    font-style: 'normal'; /* font 스타일 정의 (기울임, 일반) */
    font-weight: 400; /* font 굵기 정의 */
    /* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
    src: url('../public/fonts/CerebriSansPro-Regular.ttf');
}