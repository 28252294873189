
 
 .navbar {
  visibility: hidden;
  opacity: 0;
  background-color: transparent;
  color: white;
  transition: background-color 0.5s ease 0s;
   
    .navbar-brand {
      color: black;
      // background-image: url("../../../public/images/Logos/300ppi/Boldium_WhiteBgTransparent.png");
      // background-size: cover;
      // width: 100%;
      // height:80px;
         
       &:hover {
          color: #555;
       } 

       img.nav_brand_image{
        content: url("../../../public/images/Logos/300ppi/Boldium_WhiteBgTransparentTrimmed.gif");
        height: auto; 
        width: auto; 
        // max-width: 200px; 
        max-height: 60px;
       }
    }
    
   .navbar-nav {
     
     > li {
       
       > a {
         color: white;
         margin: 10px 5px 5px 5px;
         
       }
 
     } //end li
 
 
   } //end .navbar-nav
 
   //Collapsed styles
   .navbar-toggler {
     border-color: black;
 
       &:hover, &:focus {
        background-color: #eee;
       }
   }
     
 } //end .navbar-default
 
 
 
 /* Solid class attached on scroll past first section */
 
 .navbar.solid {
    visibility: visible;
    opacity: 1;
    transition: background-color 0.5s ease 0s;
    color: black;
 
     .navbar-brand {       
       transition: color 1s ease 0s;

       img.nav_brand_image{
        filter: brightness(0) invert(1);
       }
     }
 
     .navbar-collapse  {
        a.nav-link {
          color: white;
          transition: color 1s ease 0s;
        }
     } //end .navbar-nav

    .nav_contactUs{
      border: solid black 2px;
      border-radius: 4px;
      color: black;
      font: 700 1.2rem "Cerebri Sans Pro", sans-serif;
      padding:8px;
      margin-top:0.5rem;
      margin-right: 0.5rem;
      background-color: white;
    }

    @media (max-width: 891px){
      .nav_contactUs{
        font: 700 0.9rem "Cerebri Sans Pro", sans-serif;
        padding:6px;        
      }
    }

     //Collapsed styles
   .navbar-toggler {
    border-color: White;
    filter: brightness(0) invert(1);

      &:hover, &:focus {
        filter: opacity(100%);
      }
  }
 
 }

 @media (max-width: 891px){
  .navbar {
    
      .navbar-brand {
  
         img.nav_brand_image{
          content: url("../../../public/images/Logos/300ppi/Boldium_NoSubWhiteBgTransparentTrimmed.gif");
          height: auto; 
          width: auto; 
          // max-width: 200px; 
          max-height: 30px;
         }
      }
  }
}