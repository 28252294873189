div#testimonials {
    background-color: #fff;
    padding-bottom: 10vmin;
    padding-top: 20vmin;
    // width: 100vw;

}

@media (max-width: 768px){
    div.carousel-indicators {
        margin-bottom: -1rem;
    }
}

