/*----------------------------------------footer-styles---------------------------------------*/
#footer {
	background-color: #111111;
	margin: 0;
	min-height: 120px;
	padding: 0;
	width: 100%;

	.company_info{
		display: flex;

		.license{
			display: flex;
			align-items: center;
		}
	}

	.LBP{
		content: url(../../../public/images/LBP-Logo-Transparent.png);
		-webkit-filter: invert(1);
   		filter: invert(1);
		display: block;
		height: 6rem;
		padding: 0;
		width: 6rem;
	}

	.hr_white{
		color:white;
		opacity: 0.75;
	}

	.footer_contact{
		color:white;
	}
}
#footer>div {
	margin: 0 auto;
	max-width: 940px;
	overflow: hidden;
	padding: 4rem 1rem;
}
#footer div p {
	color: #FFFFFF;	
	font-family: Arial;
	font-size: 15px;
	font-weight: normal;		
	padding: 5px 0 0;
	text-align: left;
	text-transform: none;
	width: 400px;

	.copyright{
		float: right;
		margin: 0;
		line-height: 15px;
	}

	.lep_paragraph{
		float: left;
		line-height: 1rem;
	}
}
#footer div .connect {
	display: block;
	float: right;
	height: 32px;
	margin: 0;
	overflow: hidden;
	padding: 0;
	width: 154px;
}
#footer div .connect a {
	background: url(../../../public/images/icons.png) no-repeat;
	display: block;
	float: left;
	height: 28px;
	margin-right: 12px;
	padding: 0;
	text-indent: -99999px;
	width: 28px;
}
#footer div .connect a.facebook {
	background-position: 0 0;
}
#footer div .connect a.googleplus {
	background-position: 0 -30px;
}
#footer div .connect a.pinterest {
	background-position: 0 -60px;
	margin: 0;
}
#footer div .connect a.twitter {
	background-position: 0 -90px;
}
#footer div .connect a.facebook:hover {
	background-position: -30px 0;
}
#footer div .connect a.googleplus:hover {
	background-position: -30px -30px;
}
#footer div .connect a.pinterest:hover {
	background-position: -30px -60px;
}
#footer div .connect a.twitter:hover {
	background-position: -30px -90px;
}


@media only screen and (max-width : 720px) {
    #footer div.mb_flex{
        display: flex;
        flex-direction:column;
        justify-content:space-between;

		.company_info{
			display: flex;
			justify-content: center;
			flex-direction: column;

			.license{
				display: flex;
				flex-direction: column;
				margin-bottom: 1rem;
				
				.LBP{
					margin:0 auto;
				}
				.lep_paragraph{
					margin-top:1rem;
					width: 70vw;
				}

			}

			.connect {
				display: flex;
				float:none;
				align-self:center;
				margin-bottom: 1rem;
			}
		}

        


    }
}
